<template>
  <TN @create="create" />
</template>


<script>
import axios from '@/axios'

import TN from '@/components/tn/create.vue'
export default {
  components: {
    TN,
  },
  methods: {
    create(item) {
      axios
        .post('/authentication/tn', item)
        .then(async (res) => {
          await this.$store.dispatch('created', `TN ${item.tn}`)
          this.$router.push('/authentication/tn')
        })
        .catch((err) => {
          this.$store.dispatch('error', err)
        })
    },
  },
}
</script>

