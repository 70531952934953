<template>
  <div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >TN</label>
        <input
          v-model="item.tn"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >OCN</label>
        <input
          v-model="item.ocn"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >LATA</label>
        <input
          v-model="item.lata"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >State</label>
        <input
          v-model="item.state"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Rate Center</label>
        <input
          v-model="item.rate_center"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Carrier</label>
        <input
          v-model="item.carrier"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-outline-primary mr-2"
        @click="$router.go(-1)"
      >
        Cancel
      </button>
      <button
        class="btn btn-success"
        @click="create"
      >
        Submit
      </button>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      item: {
        tn: null,
        ocn: null,
        lata: null,
        state: null,
        rate_center: null,
        carrier: null,
      },
    }
  },
  methods: {
    create() {
      this.$emit('create', this.item);
    },
  },
}
</script>

